.wrapper footer {
  margin: auto auto 30px;
  max-width: $max-container-size;

  p {
    @include rtl-sass-value(float, left, right);
    font-size: 14px;
    display: inline-block;
    color: $white;
  }
}

@media (max-width: 1900px) {
  .wrapper footer p {
    float: none;
    display: block;
    @include rtl-sass-prop(margin-right, margin-left, 0px);
    margin-bottom: 20px;
  }
}
