.wrapper {
  background: $black;
  height: 100vh;
  overflow: hidden;
  position: relative;

  #elMain {
    padding: 0;

    .footer-word {
      font-size: 16px;
      color: $white;
      padding: 0 50px;
      // height: 200px !important;
      max-width: 1200px;
      margin: 0 auto 100px auto;
    }
  }

  .wrapper_main {
    padding: 0 3rem;
    max-width: $max-container-size;
    margin: auto;
    min-height: calc(100vh - 64px - 120px);

    .wrapper_content {
      margin: 3rem 0;
      overflow: hidden;

      .title {
        font-size: $h2-font-size;
        margin-bottom: 26px;
        font-weight: 300;
        color: $white;
        text-align: center;
      }

      .flex {
        display: flex;

        &.flex-center {
          justify-content: center;
        }

        &.flex-between {
          justify-content: space-between;
        }

        & .flex-middle {
          align-items: center;
        }

        & .flex-column {
          flex-direction: column;
        }
      }

      .item_header {
        background-color: $header-color;
        font-size: 16px;
        color: $white;
        font-weight: bold;
        height: 60px;
        line-height: 60px;
        padding-left: 22px;
        box-sizing: border-box;
      }

      .line_start {
        border-left: 1px solid $light-white;
      }

      .bottom-rouned {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .ib-btn {
        border: 1px $white solid;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        background: none;
        color: $white;
        border-radius: 5px;
      }

      .ib-button {
        border: none;
        height: 45px;
        line-height: 45px;
        padding: 0 30px;
        background: $black1;
        color: $yellow1;
        border-radius: 3px;
      }
    }
  }
}

.ib-primary-button.el-button {
  border: none;
  height: 45px;
  line-height: 45px;
  padding: 0 30px;
  background: $black1;
  color: $yellow1;
  border-radius: 3px;

  &:hover {
    color: $yellow1;
    background: $black1;
  }
}

#kakao-talk-channel-chat-button {
  position: absolute;
  visibility: hidden;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

@mixin loading {
  display: inline-block;
  width: 150px;
  height: 153px;
  background: url($loading) no-repeat center;
  background-size: cover;
}

/** loading for au */
.client-portal-loading {
  @include loading;
  animation: rotate 2s 1 linear;
  -moz-animation: rotate 2s infinite linear;
  -ms-animation: rotate 2s infinite linear;
  -webkit-animation: rotate 2s infinite linear;
  -o-animation: rotate 2s infinite linear;
}

@media (max-width: 550px) {
  .wrapper .wrapper_main {
    padding: 0 1.5rem;

    .wrapper_content h2 {
      font-size: $h4-font-size;
    }
  }
}
