.inx_header {
  background: $theme-hue;

  >ul {
    height: 60px;
    display: flex;
    display: -webkit-flex;
    /* Safari */
    align-items: center;
    /* Safari */
    justify-content: space-between;

    >.fl {
      display: flex;
      align-items: center;

      .notification_wrapper {
        @include screen-mobile() {
          display: none;
        }
      }

      .header_btn {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background: url(~@/assets/uploads/header/menuon.png) no-repeat;
        background-size: 30px 30px;
        background-position: 15px 15px;
        @include rtl-sass-prop(border-right, border-left, 1px solid #545051);
      }

      .btn_ac {
        background: url(~@/assets/uploads/header/menuoff.png) no-repeat;
        background-size: 30px 30px;
        background-position: 15px 15px;
      }

      a {
        flex-shrink: 0;
      }

      .inx_logoImg {
        width: 120px;
        height: 20px;
        margin: 0 23px;

        @include screen-mobile() {
          display: none;
        }
      }
    }

    >.fr {
      height: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      @include rtl-sass-prop(margin-left, margin-right, auto);

      /deep/ .el-dropdown {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $white;
        font-size: 16px;

        .inx_down {
          @include rtl-sass-prop(margin-right, margin-left, 20px);
        }
      }

      .member_logo {
        width: 14px;
        height: 19px;
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }

      .user_name_title {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 8px;
      }

      .user {
        display: flex;
        align-items: center;
        color: #606266;

        @include screen-mobile() {
          display: none;
        }

        .userName {
          vertical-align: text-top;
          display: inline-block;
          height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .name {
            line-height: normal;
            text-align: right;
            font-size: 14px;
            color: $primary-alt;
            vertical-align: text-bottom;
          }
        }
      }

      .inx_lang_box {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 23px;
        @include rtl-sass-prop(border-left, border-right, 1px solid #545051);
      }

      .inx_lang {
        font-weight: 600;

        >span {
          color: white;
        }

        .lang_logo {
          width: 19px;
          height: 19px;
          @include rtl-sass-prop(margin-right, margin-left, 6px);
        }

        /deep/ .el-icon-arrow-down {
          @include rtl-sass-prop(margin-left, margin-right, 6px);
        }
      }
    }
  }
}

.login_dropdown_box {
  padding: 0;
  border: 0;
  width: 213px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  margin: 12px 0 0;
  background: $theme-hue;

  /* 小箭头 */
  /deep/ .popper__arrow {
    @include rtl-sass-prop-dual(right, 0px !important, left, 40px !important);
  }

  .el-dropdown-menu__item {
    &.login_back {
      border-bottom: 1px solid #384453;

      .icon {
        background-image: url(~@/assets/uploads/header/login_back_icon.png);
      }
    }

    &.logout .icon {
      background-image: url(~@/assets/uploads/header/logout_icon.png);
    }

    .icon {
      width: 18px;
      height: 17px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
      display: inline-block;
      vertical-align: top;
      background-repeat: no-repeat;
      background-position: -18px 0;
      background-size: cover;
    }
  }
}

.lang_dropdown_box {
  width: 500px;
  padding: 40px 30px 30px;
  border: 0;
  overflow-y: auto;
  max-height: calc(100vh - 116px);
  background-color: $theme-hue;
  display: flex;
  flex-wrap: wrap;
  top: 48px !important;
  right: 0 !important;
  left: unset !important;

  .chooselang {
    position: absolute;
    top: 12px;
    left: 30px;
  }

  /* 小箭头 */
  /deep/ .popper__arrow {
    @include rtl-sass-prop-dual(right, 14px !important, left, 100px !important);
  }

  .el-dropdown-menu__item {
    width: 100px;
    height: 20px;
    flex-shrink: 0;
    padding: 6px 20px 6px 0 !important;

    span {}

    img {
      width: 22px;
      height: 16px;
      @include rtl-sass-prop(margin-right, margin-left, 5px);
    }
  }

  @media (max-width: 786px) {
    width: 70%;
    max-height: 80%;
    overflow-y: scroll;
  }
}

.login_dropdown_box,
.lang_dropdown_box {

  /* 小箭头 */
  /deep/ {
    .popper__arrow,
    .popper__arrow::after {
      border-bottom-color: $theme-hue !important;
    }

    .popper__arrow::after {
      top: 0;
      @include rtl-sass-prop-sd(left, 0px !important);
    }
  }

  .el-dropdown-menu__item {
    font-size: 0;
    line-height: normal;
    padding: 15px 18px;
    background-color: $theme-hue;
    position: relative;

    span {
      font-size: 14px;
      color: $white;
      display: inline-block;
      vertical-align: top;
    }

    &.active {
      background-color: $yellow1;
    }

    &:focus,
    &:not(.is-disabled):hover {
      background-color: $yellow1;
    }
  }

  .el-dropdown-menu__item:first-child {
    border-radius: 4px 4px 0 0;
  }

  .el-dropdown-menu__item:nth-last-child(2) {
    border-radius: 0 0 4px 4px;
  }
}

@media (max-width: 1024px) {
  header {
    padding: 15px 10px;

    ul>.fl {
      font-size: 0;
      max-width: 60%;
    }
  }

  .login_dropdown_box {

    /* 小箭头 */
    /deep/ {
      .popper__arrow {
        @include rtl-sass-prop-dual(right, 0px !important, left, 10px !important);
      }
    }
  }
}

@media (max-width: 786px) {
  header {
    padding: 15px;
  }
}

@media (max-width: 550px) {
  header>ul>.fr .user .userName {
    width: 90px;
  }
}

@media (max-width: 500px) {
  header>ul>.fr .user {
    @include rtl-sass-prop(margin-right, margin-left, 10px);
  }
}

@media (max-width: 340px) {
  header>ul>.fr .member_logo {
    display: none;
  }
}