.notification_wrapper {
  display: flex;
  display: -webkit-flex;
  /* Safari */
  align-items: center;
  /* Safari */

  span {
    font-size: 13px;
    color: $white;
  }

  .notice_mobile {
    display: none;
  }

  .noticeDisable {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    span {
      @include rtl-sass-prop(margin-right, margin-left, 0);
      @include rtl-sass-prop(padding-right, padding-left, 5px);
    }
  }

  .notice {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    width: 350px;
    max-width: 350px;

    .notification {
      white-space: nowrap;
      @include rtl-sass-value(animation,
        scroll-right-left 20s linear 0.01s infinite,
        scroll-left-right 20s linear 0.01s infinite);

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@keyframes scroll-right-left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: -100%;
  }
}

@-webkit-keyframes scroll-right-left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: -100%;
  }
}

@keyframes scroll-left-right {
  from {
    margin-right: 100%;
  }

  to {
    margin-right: -100%;
  }
}

@-webkit-keyframes scroll-left-right {
  from {
    margin-right: 100%;
  }

  to {
    margin-right: -100%;
  }
}

@media (max-width: 786px) {
  .notification_wrapper {
    width: 320px;

    .notice {
      overflow: hidden;
      max-width: 70%;
    }

    .noticeDisable {
      @include rtl-sass-prop(padding-left, padding-right, 20px);
    }
  }
}

@media (max-width: 650px) {
  .notification_wrapper {
    width: unset;
    margin-bottom: -5px;

    .notice_mobile {
      display: inline-block;

      .notification {
        margin-right: 10px;
        @include rtl-sass-prop(padding-left, padding-right, 5px);
        @include rtl-sass-prop(margin-right, margin-left, 5px);
      }
    }

    .noticeDisable {
      @include rtl-sass-prop(padding-left, padding-right, 0);
    }

    .notice {
      display: none;
    }
  }
}