#menu,
.menu_shadow,
.menu_wrapper {
  transition: all 0.5s;
}

#menu .menu_shadow {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: fixed;
  @include rtl-sass-prop(left, right, 0);
  top: 0;
  z-index: 100;
}

#menu .menu_wrapper {
  height: 100%;
  position: absolute;
  // top: 0;
  z-index: 200;
  background-color: $theme-hue;

  .swith_to {
    text-align: center;
    font-size: 13px;
    max-width: 90%;
    line-height: 40px;
    margin: 20px;
    display: block;
    color: $white;
    font-weight: 600;
    background-color: $theme-hue;
    border: 1px solid $yellow1;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    text-transform: uppercase;
  }
}

.el-menu-vertical-demo {
  padding-bottom: 160px;

  @media screen and (min-width: 768px) {
    padding-bottom: 60px;
    border: 0;
  }

  .icon {
    width: 18px;
    min-height: 18px;
    display: inline-block;

    &.icon_home {
      background: url(~@/assets/uploads/menu/menu_icon_home.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_ibreport {
      background: url(~@/assets/uploads/menu/menu_icon_account.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_rebatereport {
      background: url(~@/assets/uploads/menu/rebatep.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_tradereport {
      background: url(~@/assets/uploads/menu/trade-report.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_iblevel {
      background: url(~@/assets/uploads/menu/multi.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_ibaccounts {
      background: url(~@/assets/uploads/menu/ibaccounts.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_pendingaccounts {
      background: url(~@/assets/uploads/menu/pendingaccount.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_leads {
      background: url(~@/assets/uploads/menu/leads.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_unfundedaccounts {
      background: url(~@/assets/uploads/menu/menu_icon_funds.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }
    &.icon_allRetailAccounts {
      background: url(~@/assets/uploads/menu/menu_icon_funds.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_rebatePaymentHistory {
      background: url(~@/assets/uploads/menu/tranhis.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_settings {
      background: url(~@/assets/uploads/menu/menu_icon_my.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_referralLinks {
      background: url(~@/assets/uploads/menu/link.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }

    &.icon_contactUs {
      background: url(~@/assets/uploads/menu/menu_icon_contact.png) no-repeat;
      background-size: contain;
      background-position-x: center;
    }
  }

  .el-menu-item {
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.1);

    span {
      font-size: 14px;
      @include rtl-sass-prop(padding-left, padding-right, 15px);
      text-transform: uppercase;
      display: inline-block;
      width: 140px;
      white-space: initial;
      overflow-wrap: break-word;
      line-height: 15px;
      color: $white;
    }

    &.is-active {
      background-color: $yellow1 !important;

      .icon {
        width: 18px;
        min-height: 18px;
        display: inline-block;

        &.icon_home {
          background: url(~@/assets/uploads/menu/menu_icon_home1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_ibreport {
          background: url(~@/assets/uploads/menu/menu_icon_account1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_rebatereport {
          background: url(~@/assets/uploads/menu/rebatep1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_tradereport {
          background: url(~@/assets/uploads/menu/trade-report1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_iblevel {
          background: url(~@/assets/uploads/menu/multi1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_ibaccounts {
          background: url(~@/assets/uploads/menu/ibaccounts1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_pendingaccounts {
          background: url(~@/assets/uploads/menu/pendingaccount1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_leads {
          background: url(~@/assets/uploads/menu/leads1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_unfundedaccounts {
          background: url(~@/assets/uploads/menu/menu_icon_funds1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_allRetailAccounts {
          background: url(~@/assets/uploads/menu/menu_icon_funds1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_rebatePaymentHistory {
          background: url(~@/assets/uploads/menu/tranhis1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_settings {
          background: url(~@/assets/uploads/menu/menu_icon_my1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_referralLinks {
          background: url(~@/assets/uploads/menu/link1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }

        &.icon_contactUs {
          background: url(~@/assets/uploads/menu/menu_icon_contact1.png) no-repeat;
          background-size: contain;
          background-position-x: center;
        }
      }

      span {
        color: $black;
      }
    }
  }
}
