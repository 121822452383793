/deep/ .table {
  table {
    width: 100%;
  }
  td {
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 8px;
  }
}

.dialog_body {
  /deep/ {
    .arrow {
      background-color: $white;
      text-align: center;
      transform: translateY(-50%);
      border: 0px;
      position: absolute;

      top: 50%;
      &:hover {
        background-color: $cyan;
      }
    }
    .arrow_left {
      left: -26px;
      box-shadow: -2px 3px 10px $black;
      &.ar_arrow {
        right: -26px;
      }
    }
    .arrow_right {
      right: -26px;
      box-shadow: 2px 3px 10px $black;
      &.ar_arrow {
        left: -26px;
        right: auto;
      }
    }
  }

  .circle_button {
    border-radius: 50%;
    margin: 0 3px 1em;
    height: 2.5em;
    width: 2.5em;
    line-height: 2.7em;
    &:hover {
      cursor: pointer;
    }
  }
}

/deep/ .el-dialog__footer {
  .ar_chat img {
    transform: rotateY(180deg);
  }
}

@media (max-width: 550px) {
  .dialog_body {
    text-align: center;
  }
  .dialog_body .title {
    font-size: 16px;
  }
}

::-webkit-scrollbar {
  width: 0px;
}
